<template>
    <div class="privacy-container">
      <header class="privacy-header">
        <h1>Coronado Compound Privacy Policy</h1>
      </header>
      <main class="privacy-content">
        <section class="section">
          <h2>Information Collection and Use</h2>
          <p>
            While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. This may include your name, email address, phone number, and postal address ("Personal Information").
          </p>
        </section>

        <section class="section">
          <h2>Log Data</h2>
          <p>
            Like many sites, we collect information your browser sends when you visit ("Log Data"). This includes your computer's IP address, browser type, version, pages visited, time and date of visit, time spent on pages, and other statistics.
          </p>
        </section>

        <section class="section">
          <h2>Communications</h2>
          <p>
            We may use your Personal Information to send newsletters, marketing materials, or other relevant information. You can opt-out by following the unsubscribe link or instructions in any email.
          </p>
        </section>

        <section class="section">
          <h2>Cookies</h2>
          <p>
            Cookies are small data files with an anonymous unique identifier sent to your browser from a website and stored on your computer's hard drive. We use cookies to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, some portions of our Site may not function properly if you don't accept cookies.
          </p>
        </section>

        <section class="section">
          <h2>Security</h2>
          <p>
            The security of your Personal Information is important to us. We use commercially acceptable means to protect it, but no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee its absolute security.
          </p>
        </section>

        <section class="section">
          <h2>Changes to This Privacy Policy</h2>
          <p>
            This Privacy Policy is effective as of [Effective Date] and may be updated. We reserve the right to update or change our Privacy Policy at any time. Your continued use of the Site after we post any modifications constitutes your acknowledgment of the modifications and consent to abide by the modified Privacy Policy.
          </p>
        </section>

        <section class="section">
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us.
          </p>
        </section>
      </main>
      <footer class="privacy-footer">
        <p>&copy; {{ footerText }} All Rights Reserved.</p>
      </footer>
    </div>
  </template>

  <script>
  export default {
    name: 'PrivacyPolicy',
    data() {
    return {
      currentYear: new Date().getFullYear(),
      siteName: "Clixy",
    };
  },
  computed: {
    footerText() {
      return `${this.currentYear} ${this.siteName}, All Rights Reserved.`;
    },
  },
  mounted() {
    if (window.location.hostname.includes("clixy.net")) {
      this.siteName = "Clixy";
    } else if (window.location.hostname.includes("ezzeyfind.com")) {
      this.siteName = "EzzeyFind";
    }
    else{
      this.siteName = "Clixy";
    }
  },
  };
  </script>

  <style scoped>
  .privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    font-family: sans-serif;
    line-height: 1.5;
  }

  .privacy-header {
    text-align: center;
    margin-bottom: 2rem;
  }

  h1,
  h2 {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .privacy-content {
    color: #333;
  }

  .section {
    margin-bottom: 2rem;
  }

  ul {
    list-style: disc;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  .privacy-footer {
    text-align: center;
    font-size: 0.8rem;
    margin-top: 2rem;
    color: #aaa;
  }
  </style>
